import * as React from "react";

import Exhibit from "../../components/Exhibit";
import Shell from "../../components/Shell";

import image from "../../components/images/jazz.jpg";
import image2 from "../../components/images/pet2.jpg";
import image3 from "../../components/images/pet3.jpg";
import image5 from "../../components/images/pet5.jpg";
import image6 from "../../components/images/pet6.jpg";
import image7 from "../../components/images/pet7.jpg";

export default () => {
    return (
        <Shell>
            <div className="exhibition-page">
                <Exhibit
                    images={[
                        {
                            src: image,
                            description:
                                "Little Jazzy girl captured larger than life, an action shot for Cavoodle lovers. Digital painting.",
                        },
                        {
                            src: image2,
                            description:
                                "Dressing up is such fun! Jesse boy cuddling up in his bear hat.",
                        },
                        {
                            src: image3,
                            description:
                                "Surrounded by luxury Samira the ragdoll feels right at home. A digital painting captures her opulent air.",
                        },
                        {
                            src: image5,
                            description:
                                "A very happy Scout the pup dressed up pretty in pink, captured in a digital painting.",
                        },
                        {
                            src: image6,
                            description:
                                "A relaxed Suki keeping tabs on the world, ready to bewitch the world in this magical digital painting.",
                        },
                        {
                            src: image7,
                            description:
                                "Old, shaggy, Jesse boy. Captured enjoying life dearly and dearly loved. Digital painting.",
                        },
                    ]}
                    title="Pets"
                    cols={3}
                    subtitles={[
                        "From the affectionate to the sometimes naughty, we love our furry (and not so furry) friends.",
                        "Cherish their cheek and capture their character with a custom Pet Portrait.",
                    ]}
                />
            </div>
        </Shell>
    );
};
